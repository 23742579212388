export function cleanAccents(str: string): string {
  const accentRE = /[\u0300-\u036f]/g;
  return str.normalize('NFD').replace(accentRE, '');
}

export function stringContains(reference: string, search: string): boolean {
  const sanitizedReference = cleanAccents(reference).toLowerCase();
  const sanitizedSearch = cleanAccents(search).toLowerCase();
  return sanitizedReference.indexOf(sanitizedSearch) > -1;
}
